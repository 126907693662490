export default {
  butcher: "Butcher",
  poker: "Poker",
  barber: "Barber",
  gunsmith: "Gunsmith",
  postoffice: "Post office",
  shop: "Shop",
  fishingshop: "Fishing Shop",
  doctor: "Doctor",
  fence: "Fence",
  saloon: "Saloon",
  photostudio: "Photo Studio",
  tailor: "Tailor",
  fast_travel: "Fast Travel",
  stable: "Stable",
  hideout: "Hideout"
};
