import locations from "../../locales/locations";

const territories = [
  {
    name: locations.territories.ambarino,
    color: "blue",
    bounds: [
      [
        [74.46996144907597, -71.43173217773439],
        [74.70137790719166, -71.15874767303468],
        [74.96255415276066, -71.16308212280275],
        [75.25620242217522, -69.90137100219728],
        [75.63234925381748, -69.34274196624757],
        [75.62599033912983, -67.91572093963624],
        [75.77707676018593, -67.62170791625978],
        [75.977140980128, -67.03771591186525],
        [76.56342826533087, -67.07290649414064],
        [76.71821212477629, -65.98144054412843],
        [76.87479235488952, -65.26175022125246],
        [77.19642363379191, -64.47579860687257],
        [77.4408200764526, -64.82195377349855],
        [77.5372069010813, -66.7293691635132],
        [77.68975065312289, -67.717924118042],
        [77.77828909337592, -69.20687198638917],
        [77.9570171162369, -70.11251449584962],
        [78.04967916422312, -70.37034988403322],
        [78.10394094461292, -70.92026710510255],
        [78.2671759417988, -71.48460388183595],
        [78.50403895571242, -70.28932571411134],
        [78.74027316465302, -70.07715225219728],
        [78.87688913234396, -69.3545436859131],
        [79.39155531758483, -69.89218711853029],
        [79.87156531655641, -69.49376106262208],
        [80.03062724215572, -69.47178840637208],
        [80.35915178659175, -69.28853988647462],
        [80.5511688161522, -69.63014602661134],
        [80.68935804066858, -68.18982124328615],
        [80.58003881637406, -65.94569206237794],
        [80.27298738086326, -64.7564220428467],
        [80.33452641808397, -64.0675449371338],
        [80.34303935226727, -63.288545608520515],
        [80.52988246345686, -62.31033325195313],
        [80.70966559961406, -62.03095436096192],
        [80.8131780564877, -61.74367904663087],
        [81.01973263207293, -62.838621139526374],
        [81.06607677576626, -63.501749038696296],
        [81.05010770048813, -65.66502571105958],
        [81.11190520187846, -68.38251113891603],
        [81.31710400674233, -68.52516174316408],
        [81.39926796218751, -67.17186927795412],
        [81.6585209982677, -65.82080841064455],
        [82.08546631089601, -65.12120246887208],
        [82.25620723416588, -64.54158782958986],
        [82.28940917879176, -62.097773551940925],
        [82.31905177181152, -60.048565864562995],
        [82.24395591732362, -58.33628654479981],
        [82.2763046027856, -57.82151699066163],
        [82.34550062473922, -57.84799575805665],
        [82.39071467091576, -58.39795589447022],
        [82.5452600850012, -58.685274124145515],
        [82.71310847627923, -59.352951049804695],
        [82.77928117727478, -58.97452354431153],
        [83.03205226544372, -58.34315299987794],
        [83.01550816183656, -57.661571502685554],
        [82.99458201563405, -56.819658279418945],
        [82.93569434513115, -56.533498764038086],
        [82.90209005645077, -55.932683944702156],
        [82.81931338638759, -55.066995620727546],
        [82.82381804821595, -53.657312393188484],
        [82.71040811067058, -51.06496810913087],
        [82.45253038955494, -50.197906494140625],
        [82.34357399465154, -49.97380256652833],
        [82.22796035724507, -49.58893775939942],
        [82.05208011733004, -49.47761535644532],
        [81.85131085855667, -49.317970275878906],
        [81.68360472863867, -50.25206565856934],
        [81.539197701196, -50.66765785217286],
        [81.28431859270614, -50.870990753173835],
        [81.17974374091486, -51.22469902038575],
        [81.10780652948861, -51.15277290344239],
        [81.10316175574519, -50.7359790802002],
        [81.21792017511456, -50.4298210144043],
        [81.40686984088542, -49.794073104858406],
        [81.52830497398062, -48.96941184997559],
        [81.66401661434428, -48.328685760498054],
        [81.77953649833313, -48.03136825561524],
        [82.05142734740126, -48.411340713500984],
        [82.27568167151834, -48.196420669555664],
        [82.36366268481501, -47.65199661254883],
        [82.41691254585844, -46.60194396972656],
        [82.35787786307313, -45.6822681427002],
        [82.24562952752102, -45.279207229614265],
        [82.10846734152572, -45.341863632202156],
        [82.02968941460841, -45.26289939880372],
        [81.94630717142736, -44.91751670837403],
        [81.85498412431787, -44.84696388244629],
        [81.78011327541036, -44.716415405273445],
        [81.77760952606266, -44.1343116760254],
        [81.80116875596244, -43.4099006652832],
        [81.80959795197764, -42.691669464111335],
        [81.88796959249441, -42.07815170288087],
        [81.90652750278649, -41.65989875793458],
        [82.0141908990811, -41.30361557006837],
        [82.08804233267773, -40.837984085083015],
        [82.17750489116209, -40.32832145690919],
        [82.2251857773869, -39.359893798828125],
        [82.23887497832305, -38.81160736083985],
        [82.13241241591078, -38.09663772583008],
        [82.23652167894863, -36.53649330139161],
        [82.3033538388659, -35.36687850952149],
        [82.27804623816564, -34.471664428710945],
        [82.2308035703837, -33.77368927001954],
        [81.95663004157971, -33.77368927001954],
        [81.86344340225737, -33.265914916992195],
        [81.78088633908888, -32.234659194946296],
        [81.65756839114437, -32.13243484497071],
        [81.51187643078033, -32.463912963867195],
        [81.31558136893169, -32.94267654418946],
        [81.23602495005368, -33.92518043518067],
        [81.08067363153171, -35.573301315307624],
        [80.90524180048266, -38.470172882080085],
        [80.94004295745188, -40.731124877929695],
        [80.8647772277919, -42.39091873168945],
        [80.93174062013703, -44.40519332885742],
        [80.65535854472989, -45.041971206665046],
        [80.5860213298112, -46.30522727966309],
        [80.49091124720512, -47.36206054687501],
        [80.45898256469509, -46.83540344238281],
        [80.40488335310606, -45.0853157043457],
        [80.28140910759683, -44.646635055542],
        [80.1603371570871, -44.19645309448243],
        [80.09821659847621, -43.68378639221192],
        [80.11122594289273, -42.22826957702637],
        [80.18205099593679, -41.461715698242195],
        [80.37754773926343, -40.061216354370124],
        [80.52873851325413, -38.5246753692627],
        [80.5216175237114, -37.70293235778809],
        [80.53222642745675, -36.60412788391114],
        [80.5746288437573, -35.73852539062501],
        [80.55385973008714, -34.45509910583497],
        [80.53674329017709, -32.7663803100586],
        [80.53840834438864, -32.010984420776374],
        [80.63736323362879, -30.948657989501957],
        [80.69207936108498, -29.46619033813477],
        [80.68570523388703, -28.28318595886231],
        [80.68587193279987, -26.34607315063477],
        [80.70155617078595, -25.296020507812504],
        [80.79034781268254, -23.962469100952152],
        [80.88783232517208, -21.896953582763675],
        [80.88872940708127, -20.44160842895508],
        [80.98620329954255, -17.360115051269535],
        [81.01678466247344, -14.474744796752931],
        [81.12432240821941, -12.81907081604004],
        [81.14359652464269, -10.055322647094728],
        [80.9859612450844, -7.238359451293945],
        [80.96758665656478, -5.038862228393556],
        [80.81931506809487, -3.31838607788086],
        [80.67796437093189, -1.9068145751953127],
        [80.67537803092002, -0.025749206542968753],
        [80.58262327486146, 1.1515903472900393],
        [80.54008721523077, 1.9208049774169924],
        [80.60760285775355, 4.04399871826172],
        [80.70019698219623, 6.212167739868164],
        [80.73324698160373, 7.2696876525878915],
        [80.64893160193992, 9.343442916870119],
        [80.4921164356535, 11.596240997314455],
        [80.3989299522869, 14.617395401000977],
        [80.12991378552718, 16.379842758178714],
        [79.97264399555273, 18.11259269714356],
        [79.7083671410926, 19.07089233398438],
        [79.58017044253543, 19.55154418945313],
        [79.41297600985598, 19.885511398315433],
        [79.32616153110087, 21.032295227050785],
        [79.31849627362733, 22.31975555419922],
        [79.01407650062204, 22.541885375976562],
        [78.69274638682232, 22.80289649963379],
        [78.27755625603442, 22.454767227172855],
        [78.00393580892563, 21.55620574951172],
        [77.69162621587996, 21.753273010253906],
        [77.24311207933306, 21.756792068481445],
        [76.44748207529136, 21.340942382812504],
        [76.0372958660727, 21.0677433013916],
        [75.59519003599247, 21.365060806274414],
        [75.15338482524601, 21.41939163208008],
        [74.53381109379046, 20.79222679138184],
        [74.27686621646724, 20.167808532714844],
        [73.97955997753894, 18.92120361328125],
        [73.76915983656761, 18.49702835083008],
        [73.96647923807564, 15.558872222900392],
        [73.6926475780804, 13.331737518310549],
        [73.38793464903121, 10.95714569091797],
        [73.27424111830938, 8.103103637695314],
        [73.36529549707944, 4.934062957763673],
        [73.62565961983388, 3.2339286804199223],
        [73.94963908162573, 0.4871749877929688],
        [74.13379635776879, -2.7462387084960938],
        [74.15593264839102, -4.964275360107423],
        [74.18351390890108, -5.281119346618653],
        [74.88373306943166, -5.548095703125],
        [75.16905745133383, -5.650019645690918],
        [75.64720792873166, -5.1397132873535165],
        [76.18221807156193, -5.078644752502442],
        [76.88454374696119, -5.796790122985841],
        [77.26963762095426, -6.553516387939454],
        [77.55704771971341, -6.61428451538086],
        [77.93857570112422, -7.260031700134278],
        [78.14713139533079, -8.471102714538576],
        [78.2750884812591, -11.042160987854006],
        [78.04419513846352, -11.882443428039553],
        [78.2460646585224, -11.903514862060549],
        [78.08735133493293, -12.406740188598635],
        [77.90339018624631, -12.874174118041994],
        [77.88808293147041, -13.356971740722658],
        [77.73925331125106, -13.735527992248537],
        [77.71001925305112, -14.240040779113771],
        [77.71560858447418, -14.445433616638185],
        [77.63904070969872, -14.478220939636232],
        [77.51316938208787, -15.084829330444338],
        [77.38199694706178, -15.315842628479006],
        [77.26148315425895, -15.845246315002443],
        [77.16345682214887, -16.5459680557251],
        [77.07603905503522, -16.796636581420902],
        [76.9886932050558, -17.204332351684574],
        [76.91696138801903, -17.729616165161136],
        [76.89551406898119, -18.287773132324222],
        [76.8955043388862, -19.76526260375977],
        [76.93404783828643, -20.461950302124027],
        [76.93430978912649, -21.334934234619144],
        [76.98414157203096, -21.88107490539551],
        [77.10327970422107, -22.32434749603272],
        [77.27718207525287, -22.343444824218754],
        [77.13186894811986, -22.61664390563965],
        [77.13438235195821, -24.182581901550297],
        [77.09989807172404, -24.697179794311527],
        [76.88200186240512, -25.195083618164066],
        [76.7905240419209, -24.97866153717041],
        [76.72409689871934, -25.145602226257328],
        [76.52692136029854, -25.354213714599613],
        [76.3689486545383, -26.74806118011475],
        [76.31583079775963, -28.20366382598877],
        [76.36859466671359, -28.959488868713382],
        [76.42689086843112, -29.125699996948246],
        [76.39726875874146, -29.315428733825687],
        [76.31040832519095, -29.29165363311768],
        [76.12324135412669, -29.43820953369141],
        [75.84321640361463, -29.70711708068848],
        [75.71671324913466, -29.576225280761722],
        [75.58237313602034, -29.42524909973145],
        [75.48167326037795, -29.15655612945557],
        [75.32388557393831, -29.019656181335453],
        [75.25511019886004, -28.803191184997562],
        [75.27104880719523, -28.621101379394535],
        [75.11843142869093, -28.667836189270023],
        [75.00643893860378, -28.961162567138675],
        [75.02728664290997, -29.46794986724854],
        [75.02755274217114, -29.918990135192875],
        [74.94050340053366, -30.384578704833988],
        [74.73254231644289, -31.02521896362305],
        [74.67137670114077, -32.00956821441651],
        [74.42907249009991, -33.00833702087403],
        [74.40661611973721, -33.941831588745124],
        [74.22811121706512, -34.25687313079835],
        [73.9198064177078, -34.236187934875495],
        [73.64467955947052, -34.45308208465577],
        [73.56056601700533, -34.864425659179695],
        [73.49926809035321, -35.00664710998536],
        [73.44958571505167, -35.89130401611329],
        [73.5116233809279, -36.45933151245118],
        [73.41036006342075, -37.32669353485108],
        [73.12398597728645, -38.29327583312988],
        [73.07460565017789, -38.708138465881355],
        [72.76269132891363, -39.390535354614265],
        [72.54049489364267, -39.64201927185059],
        [72.35489175174965, -39.39444065093994],
        [72.24253060183632, -39.00970458984375],
        [72.17547284652409, -38.74045372009278],
        [72.14351029816606, -39.0915870666504],
        [72.03781606926705, -39.582796096801765],
        [71.82035255152175, -39.73085403442383],
        [71.6601253577303, -39.573783874511726],
        [71.35852067954814, -40.31583309173585],
        [71.22940579268825, -40.94621658325196],
        [71.19244471641734, -41.825981140136726],
        [71.26209253640421, -42.8697681427002],
        [71.21618581560355, -43.57126235961915],
        [71.14516697907384, -44.032301902770996],
        [71.00207292976258, -44.520893096923835],
        [70.75073716858603, -45.13127803802491],
        [70.96290300055043, -46.60323143005372],
        [71.4697106651619, -49.048547744750984],
        [71.96793847982748, -50.560369491577156],
        [72.74273972078167, -53.25098991394044],
        [73.18989019084519, -55.99400997161866],
        [73.34856700407141, -58.594379425048835],
        [73.7563680720343, -62.569670677185066],
        [74.06183367484006, -65.92998504638673],
        [74.29457997990309, -68.40675830841066],
        [74.44419084021392, -69.86454963684083]
      ]
    ]
  },
  {
    name: "New Austin",
    color: "orange",
    bounds: [
      [
        [9.967836189153537, -163.45115661621097],
        [11.876086373327269, -161.9916915893555],
        [13.19749877872282, -160.01518249511722],
        [13.387949633440696, -162.84072875976565],
        [14.2331035654149, -166.31721496582034],
        [16.78416295702938, -159.1905212402344],
        [23.356124935868777, -158.57597351074222],
        [29.611670115197406, -152.6701354980469],
        [34.72722319062358, -148.79264831542972],
        [36.531984717947445, -145.64609527587893],
        [41.741090260654076, -138.51493835449222],
        [42.058469844272544, -134.5866394042969],
        [38.79021858794648, -127.61581420898439],
        [38.756225137839074, -124.54822540283205],
        [37.81846319511331, -123.80184173583986],
        [40.688708709249646, -118.12500000000001],
        [44.36951444646586, -113.75621795654297],
        [46.632464724627475, -100.96401214599611],
        [43.21818744408914, -87.43263244628906],
        [42.18045144249421, -79.7816848754883],
        [41.14039880964587, -78.03897857666016],
        [42.55459772508892, -74.31152343750001],
        [40.61916465186328, -69.00341033935548],
        [38.46272937738584, -60.27975082397462],
        [37.71180059181106, -53.928451538085945],
        [32.679840539897484, -50.85708618164063],
        [24.46433820546543, -63.48363876342774],
        [19.92187413647428, -77.77204513549806],
        [16.060825487315018, -91.00765228271486],
        [10.138552086404047, -91.48366928100587],
        [10.161194723323316, -101.97629928588867],
        [6.075181698015186, -109.13097381591798],
        [8.078605286066836, -117.16575622558595],
        [5.748882059228117, -122.14891433715822],
        [4.786521722186024, -130.18524169921878],
        [-0.05475997091136111, -136.20592117309573],
        [5.552430844017411, -144.59209442138675],
        [6.749168923865482, -151.58145904541018],
        [7.4176231575808105, -157.70015716552737],
        [7.541529290443372, -161.9034576416016]
      ]
    ]
  },
  {
    name: "West Elizabeth",
    bounds: [
      [
        [37.844901469765006, -54.085006713867195],
        [38.86377092059628, -56.65237426757813],
        [38.67934563721878, -59.8971176147461],
        [37.87539539764788, -61.68668746948243],
        [37.79934038764372, -63.63075256347657],
        [38.9181507130663, -67.26842880249025],
        [39.627242631971086, -69.06932830810548],
        [41.258452562152115, -70.78268051147462],
        [41.67598909594535, -73.07075500488283],
        [42.70060440808085, -74.25985336303712],
        [43.784603744220945, -75.68138122558595],
        [46.3075727356299, -77.0419692993164],
        [48.29313010839769, -78.78124237060547],
        [49.3995797187007, -79.81327056884767],
        [49.464330957022646, -83.73470306396486],
        [50.78282250817873, -85.14953613281251],
        [51.71990358213198, -84.16128158569337],
        [51.84574723897852, -83.36305618286134],
        [52.78791838206992, -82.80155181884767],
        [52.45444028433049, -81.97002410888673],
        [54.227410206515934, -80.44017791748048],
        [54.83708187820751, -78.89556884765626],
        [54.19538677476914, -77.47301101684572],
        [54.24546889907602, -76.20872497558595],
        [54.93315843330341, -78.09476852416994],
        [55.971492770905385, -77.0855712890625],
        [57.49156788813758, -77.40331649780275],
        [58.01655478055704, -76.54277801513673],
        [58.615843791158845, -77.06153869628908],
        [59.85128170836741, -73.374080657959],
        [61.42103513897865, -74.4533157348633],
        [61.094317379301614, -76.78876876831056],
        [61.324891974414484, -78.73352050781251],
        [61.7350213295472, -79.77121353149415],
        [60.40749513045394, -81.65193557739259],
        [59.23261534221228, -82.83348083496094],
        [58.93938173738755, -85.00928878784181],
        [60.534319275500465, -86.99060440063478],
        [61.48330016834436, -88.36389541625978],
        [62.478942740005124, -89.31970596313478],
        [63.28290804859626, -90.01853942871094],
        [64.3074788161246, -89.34133529663087],
        [65.44706439129287, -89.3785858154297],
        [65.87788184269262, -88.13850402832033],
        [67.21134736748857, -87.86470413208009],
        [67.54210111306456, -86.7470169067383],
        [68.06294997668384, -86.35485649108888],
        [68.52283042891699, -85.21416664123535],
        [68.87969781199125, -86.85404777526855],
        [69.624388514573, -86.48712158203126],
        [69.24982502085636, -85.0646495819092],
        [69.605314022262, -83.84722709655762],
        [70.08266750366913, -84.13879394531251],
        [70.26513331357236, -84.3142318725586],
        [70.63980554921122, -83.16753387451173],
        [71.02884996806442, -81.48628234863283],
        [71.5514369489632, -80.90898513793947],
        [72.02476213997693, -81.82119369506837],
        [72.43584546690981, -80.68016052246095],
        [72.66787501423777, -83.8443946838379],
        [73.9427082738845, -85.47157287597658],
        [74.72188213417823, -85.6490707397461],
        [74.73146871402588, -84.13175582885744],
        [75.0742479809633, -83.37352752685548],
        [75.61469301455472, -83.27053070068361],
        [75.61750750039359, -82.30802536010744],
        [75.79723361243961, -81.59339904785158],
        [75.53571104517705, -79.71937179565431],
        [75.53498211848165, -78.5211753845215],
        [75.28418002404854, -76.94257736206056],
        [74.34760462116932, -76.56921386718751],
        [74.32582212494094, -75.6786346435547],
        [74.48158539384453, -71.23947143554689],
        [74.3524205883602, -68.81956100463869],
        [73.91975887044072, -64.14213180541994],
        [73.56807003081536, -60.881767272949226],
        [73.24516786186724, -56.33411407470704],
        [72.44371779156162, -51.89117431640626],
        [71.2282319675624, -47.88133621215821],
        [70.7353804094319, -45.294914245605476],
        [70.00679912481459, -47.01324462890626],
        [69.5131998397972, -48.103466033935554],
        [68.5368417249637, -49.27145004272462],
        [67.32530670602658, -49.6384620666504],
        [66.62336696367615, -48.605918884277344],
        [66.1257960965374, -46.46581649780274],
        [66.33888290222605, -43.5142707824707],
        [67.2758890665674, -40.59190750122071],
        [68.03493999532108, -38.53248596191406],
        [68.26042358694943, -36.115837097167976],
        [68.14191958434719, -33.737812042236335],
        [67.43134845653933, -32.53137588500977],
        [66.32020616640206, -32.81684875488282],
        [65.4113031052888, -34.36283111572266],
        [64.66210511034883, -36.92676544189454],
        [64.0822553895029, -38.98412704467774],
        [63.55367490804209, -40.2370834350586],
        [62.36283055353362, -40.46195983886719],
        [61.18272878004901, -39.596271514892585],
        [59.622196723961785, -38.50210189819337],

        [58.93592742145993, -37.151813507080085],
        [58.083684993160425, -35.02630233764649],
        [54.2292164313931, -30.096530914306644],
        [58.0669823596297, -38.81160736083985],
        [56.52777901406051, -38.99631500244141],
        [55.959290894552474, -37.89424896240235],
        [56.925868419691845, -41.69260025024414],
        [56.26537787081465, -41.91043853759766],
        [55.50336097409282, -40.444965362548835],
        [53.89867439403615, -39.24951553344727],
        [51.17105611838576, -38.45077514648438],
        [49.91177178578665, -37.26751327514649],
        [46.88119770540149, -38.36545944213868],
        [44.38656882676517, -38.997001647949226],
        [42.663629925384384, -40.07812500000001],
        [42.44372793752476, -41.19632720947266],
        [42.48425110546248, -44.30940628051758],
        [43.63234790703911, -46.90784454345703],
        [41.94761750180235, -51.34374618530274],
        [40.20273934798704, -51.959152221679695]
      ]
    ]
  }
];

export default territories;
